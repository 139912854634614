<template>
  <v-data-table
    :headers="headers"
    :items="carRequest"
    sort-by="Delivery Date"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-row>
        <v-col cols="auto" class="mx-5">
          <v-sheet class="v-sheet--offset" elevation="5">
            <v-toolbar color="primary" class="round-coners" dark>
              <v-icon large>mdi-car</v-icon>
            </v-toolbar>
          </v-sheet>
        </v-col>
        <v-col>
          <v-card-title color="primary">
            <h3 class="font-weight-light">Car Rental Request</h3>
            <v-col class="d-flex align-end flex-column">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-card-title>
        </v-col>
      </v-row>
    </template>

    <template
      v-slot:[`item.loss_of_use_requests[0].car_rentals[0].status`]="{ value }"
    >
      <v-chip :color="getColor(value)" dark>
        {{ value }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    dialog: false,
    search: '',
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Customer Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Phone No.',
        align: 'start',
        sortable: true,
        value: 'phone_number',
      },
      { text: 'Email', value: 'email_address' },
      { text: 'Cover Type', value: 'cover_type' },
      {
        text: 'Delivery Location',
        value: 'loss_of_use_requests.0.delivery_location.name',
      },
      { text: 'Delivery Date', value: 'loss_of_use_requests.0.request_date' },
      {
        text: 'Vehicle Assigned',
        value:
          'loss_of_use_requests.0.car_rentals.0.vehicles.0.registration_number',
      },
      {
        text: 'Vendor',
        value:
          'loss_of_use_requests[0].car_rentals[0].vehicles[0].vendors[0].name',
      },
      {
        text: 'Status',
        value: 'loss_of_use_requests[0].car_rentals[0].status',
      },
    ],
  }),

  computed: {
    ...mapState(['carRequest', 'loadingTable']),
  },

  mounted() {
    this.$store.dispatch('fetchRequest')
  },
  created() {
    this.$store.state.loadingTable = true
  },

  methods: {
    getColor(value) {
      if (value === 'booked') return 'green'
      else if (value === 'dispatched') return 'secondary'
      else if (value === 'canceled') return 'error'
      else return 'primary'
    },
  },
}
</script>
